import moment from "moment-timezone";
import ObjectPath from "object-path";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ManageSchedule from "@/core/plugins/schedule.plugin";
import { toSafeNumber } from "@/core/lib/math.lib";
import { head, cloneDeep } from "lodash";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const todayDate = moment(new Date());

const formatDate = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format("DD MMMM YYYY");
  }
};

const scheduleTypeList = [
  { color: "deep-orange darken-4 white--text", border: true, disabled: false, max_allowed: 0, group: "onetime", text: "One Time", value: "onetime" },
  { color: "teal darken-3 white--text", border: false, disabled: false, max_allowed: 0, group: "monthly", text: "Quarterly", value: "quarterly" },
  { color: "lime darken-4 white--text", border: false, disabled: false, max_allowed: 0, group: "monthly", text: "Twice A Year", value: "bi_annually" },
  { color: "brown darken-2 white--text", border: false, disabled: false, max_allowed: 0, group: "monthly", text: "Yearly", value: "annually" }
];

const validScheduleType = ["onetime", "daily", "weekly", "monthly"];

const adjustedStartTime = moment(todayDate).startOf("hour").add(Math.ceil(todayDate.minutes() / 15) * 15, "minutes");
const adjustedStartEnd = moment(todayDate).add(1, "hour").startOf("hour").add(Math.ceil(todayDate.minutes() / 15) * 15, "minutes");

const initialRecurringSchedule = {
  start_date: moment(todayDate).format("YYYY-MM-DD"),
  end_date: moment(todayDate).format("YYYY-MM-DD"),
  start_time: adjustedStartTime.format("hh:mm A"),
  end_time: adjustedStartEnd.format("hh:mm A"),
};

// action types
export const UPDATE_SCHEDULE_STATE = "JtxContractUpdateScheduleState";
export const GET_RECURRING_DATE = "JtxContractGetRecurringDate";
export const SET_RECURRING_DATE = "JtxContractSetRecurringDate";
export const SET_RAW_RECURRING_DATE = "JtxContractSetRawRecurringDate";
export const CLEAR_CONTRACT_STATE = "JtxClearContractState";

// mutation types
export default {
  state: {
    dbContractWeekDays: [
      { id: 1, short_name: "Sun", long_name: "Sunday" },
      { id: 2, short_name: "Mon", long_name: "Monday" },
      { id: 3, short_name: "Tue", long_name: "Tuesday" },
      { id: 4, short_name: "Wed", long_name: "Wednesday" },
      { id: 5, short_name: "Thu", long_name: "Thursday" },
      { id: 6, short_name: "Fri", long_name: "Friday" },
      { id: 7, short_name: "Sat", long_name: "Saturday" },
    ],
    dbContractRecurringScheduleTypeList: cloneDeep(scheduleTypeList),
    dbDefaultRecurringSchedule: {
      start_date: initialRecurringSchedule.start_date,
      end_date: initialRecurringSchedule.end_date,
      start_time: initialRecurringSchedule.start_time,
      end_time: initialRecurringSchedule.end_time
    },
    dbContractRecurringSchedule: {
      is_town_council : null,
      type: head(cloneDeep(scheduleTypeList)),
      weeks: [],
      occurrence: 1,
      recurring_pattern: 1,
      week_day: 1,
      week_count: 1,
      message: null,
      end_mode: 2 /* occurrences = 2; end-date = 3 */,
      start_date: initialRecurringSchedule.start_date /* FORMAT - YYYY-MM-DD*/,
      end_date: initialRecurringSchedule.end_date /* FORMAT - YYYY-MM-DD*/,
      start_time: initialRecurringSchedule.start_time /* FORMAT - hh:mm A*/,
      end_time: initialRecurringSchedule.end_time /* FORMAT - hh:mm A*/,
    },
    dbContractDurationList: [
      { id: 1, short_name: "1st", long_name: "First" },
      { id: 2, short_name: "2nd", long_name: "Second" },
      { id: 3, short_name: "3rd", long_name: "Third" },
      { id: 4, short_name: "4th", long_name: "Fourth" },
      { id: 5, short_name: "Last", long_name: "Last" }
    ],
    dbContractScheduleOutput: [],
    dbContractRawScheduleOutput: [],
  },
  getters: {
    contractScheduleOutput(state) {
      return state.dbContractScheduleOutput;
    },
    contractScheduleLength(state) {
      if(state.dbContractRecurringSchedule.type.value == 'onetime'){
        return 1;
      }
      return state.dbContractScheduleOutput.length;
    },
    contractDurationList(state) {
      return state.dbContractDurationList;
    },
    contractRawScheduleOutput(state) {
      return state.dbContractRawScheduleOutput;
    },
    contractRecurringSchedule(state) {
      return state.dbContractRecurringSchedule;
    },
    contractWeekDays(state) {
      return state.dbContractWeekDays;
    },
    contractRecurringScheduleTypeList(state) {
      return state.dbContractRecurringScheduleTypeList;
    },
  },
  actions: {
    [GET_RECURRING_DATE](context) {
      const recurringSchedule = context.state.dbContractRecurringSchedule;
      const defaultSchedule = context.state.dbDefaultRecurringSchedule;

      if (!validScheduleType.includes(recurringSchedule?.type?.group)) {
        return false;
      }

      const resetRecurringSchedule = () => {
        context.commit(UPDATE_SCHEDULE_STATE, { key: "dbContractRecurringSchedule.message", value: null });
        context.commit(UPDATE_SCHEDULE_STATE, { key: "dbContractRecurringSchedule.end_date", value: defaultSchedule.end_date });        
        context.commit(SET_RECURRING_DATE, []);
        context.commit(SET_RAW_RECURRING_DATE, []);
      };

      const startDate = moment(recurringSchedule.start_date).format("YYYY-MM-DD");
      const occurrences = recurringSchedule.end_mode == 2 ? toSafeNumber(recurringSchedule.occurrence) : 1000;
      const endDate = ManageSchedule.getEndDate(recurringSchedule);
      const selectedDays = recurringSchedule.weeks;

      if (recurringSchedule?.type?.group == "weekly" && !selectedDays.length) {
        resetRecurringSchedule();
        ErrorEventBus.$emit("update:error", InitializeError("Please select weekday."));
        return false;
      }

      const weekDay = toSafeNumber(recurringSchedule?.week_day, -1);
      const weekCount = toSafeNumber(recurringSchedule?.week_count, -1);
      const pattern = toSafeNumber(recurringSchedule?.recurring_pattern, 1);
      const endMode = toSafeNumber(recurringSchedule?.end_mode, 2);
      const scheduleType = recurringSchedule?.type?.value;

      let scheduleManager = new ManageSchedule(scheduleType, startDate, occurrences, endDate, endMode, pattern, weekDay, weekCount);

      let scheduleDate = [];

      if (recurringSchedule?.type?.value == "onetime") {
        scheduleDate = scheduleManager.generateOneTimeSchedule();
      }

      if (recurringSchedule?.type?.group == "monthly") {
        if (recurringSchedule?.type?.value == "monthly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(1);
        }

        if (recurringSchedule?.type?.value == "quarterly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(3);
        }

        if (recurringSchedule?.type?.value == "bi_annually") {
          scheduleDate = scheduleManager.generateMonthlySchedule(6);
        }

        if (recurringSchedule?.type?.value == "annually") {
          scheduleDate = scheduleManager.generateMonthlySchedule(12);
        }
      }

      if (!scheduleDate.length) {
        resetRecurringSchedule();
        ErrorEventBus.$emit("update:error", InitializeError("Oops... Dates are not available, please select valid schedule."));
        return false;
      }

      const scheduleStartDate = scheduleDate.length ? scheduleDate[0] : null;
      const scheduleEndDate = scheduleDate.length ? moment(scheduleDate[scheduleDate.length - 1]).toDate() : null;

      const endTime = moment(`${recurringSchedule.start_date} ${recurringSchedule.end_time}`).format("HH:mm:ss");

      /*let [hour, minute, second] = endTime.split(":");

      if (scheduleEndDate instanceof Date) {
        scheduleEndDate.setHours(hour);
        scheduleEndDate.setMinutes(minute);
        scheduleEndDate.setSeconds(second);
      }*/

      const message = scheduleManager.getMessage("Contract", recurringSchedule, formatDate(scheduleStartDate), formatDate(scheduleEndDate), scheduleDate.length);

      context.commit(UPDATE_SCHEDULE_STATE, { key: "dbContractRecurringSchedule.message", value: message });
      // context.commit(UPDATE_SCHEDULE_STATE, { key: "dbContractRecurringSchedule.end_date", value: moment(scheduleEndDate).format("YYYY-MM-DD") });

      const combineDate = scheduleManager.combineDate(scheduleDate, endTime);

      context.commit(SET_RECURRING_DATE, combineDate);
      context.commit(SET_RAW_RECURRING_DATE, combineDate);

      scheduleManager = undefined;

    },
  },
  mutations: {
    [CLEAR_CONTRACT_STATE](state) {      
      state.dbContractRecurringSchedule = {
        type: head(cloneDeep(scheduleTypeList)),
        weeks: [],
        is_town_council: null,
        occurrence: 1,
        recurring_pattern: 1,
        week_day: 1,
        week_count: 1,
        message: null,
        end_mode: 2 /* occurrences = 2; end-date = 3 */,
        start_date: initialRecurringSchedule.start_date /* FORMAT - YYYY-MM-DD*/,
        end_date: initialRecurringSchedule.end_date /* FORMAT - YYYY-MM-DD*/,
        start_time: initialRecurringSchedule.start_time /* FORMAT - hh:mm A*/,
        end_time: initialRecurringSchedule.end_time /* FORMAT - hh:mm A*/,
      }

      state.dbContractScheduleOutput = [];
      state.dbContractRawScheduleOutput = [];
      state.dbContractRecurringScheduleTypeList = cloneDeep(scheduleTypeList);
    },
    [SET_RAW_RECURRING_DATE](state, payload) {
      state.dbContractRawScheduleOutput = cloneDeep(payload);
    },
    [SET_RECURRING_DATE](state, payload) {
      state.dbContractScheduleOutput = cloneDeep(payload);
    },
    [UPDATE_SCHEDULE_STATE](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
  },
};
